const configration = {
    // API for user management
    // APIURL: "http://localhost:8000/",
    // APIURL: "http://ec2-3-235-225-63.compute-1.amazonaws.com:8000/",
    APIURL: "https://camdenpassageapi.hyper-clear.com:8000/",
    // API for block management, sub front-end has the defferent one with APIURL
    // CHAINAPIURL: "http://localhost:8000/",
    CHAINAPIURL: "https://centralblockchain.hyper-clear.com:8000/",

    // This is the unique value of every company like primary key
    // COMPANYNAME: "Company1"
    COMPANYID: "camdenpassage",
    COMPANYNAME: "Camden Passage"
};
export default configration;
