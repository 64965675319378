import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import { withStyles } from '@material-ui/core';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography 
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import schema from '../SignUp/schema';
import { addUser } from 'services/user';
import {Toast} from 'services/common'
import { ToastContainer } from 'react-toastify';
import validate from 'validate.js';
import validators from 'common/validators';
import _ from 'underscore';
import styles from './styles';
import CONFIG from '../../services/config';

validate.validators.checked = validators.checked;
class AddUser extends Component {
  
  state = {
    values: {
      // userType:'DISTRIBUTOR',
      fullName: '',
      email: '',
      password: '',
      mobileNumber:'',
      // companyName: '',
      address:'',
      policy:true
    },
    touched: {
      fullName: false,
      email: false,
      password: false,
      mobileNumber:false,
      // companyName: false,
      address:false
    },
    errors: {
      fullName: null,
      email: null,
      password: null,
      mobileNumber:null,
      address:null,
      // companyName: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  validateForm = _.debounce(() => {
    const { values } = this.state;
    const newState = { ...this.state };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    
    // validate Add user Form
    newState.isValid = errors ? false : true;
    this.setState(newState);
  }, 300);
  handleFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;
    this.setState(newState, this.validateForm);
  };
  handleAddUser = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;
      this.setState({ isLoading: true });
      let add = await addUser({
        // role:values.userType,
        name: values.fullName,
        email: values.email,
        password: values.password,
        mobileNumber:values.mobileNumber,
        // company: values.companyName,
        company: CONFIG.COMPANYNAME,
        companyid: CONFIG.COMPANYNAME,
        address:values.address,
        isActive:true
      });
      
      if(add.success === true){
        history.push('/users');
      }else{
        Toast.errorToast(add.error);
        this.setState({
          isLoading: false,
          serviceError: add.error
        });
      }
    } catch (error) {
      Toast.errorToast(error.error !== undefined ? error.error : "server error");
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };
  
    render() {
      const { classes } = this.props;
      const roleList =['DISTRIBUTOR','DOCTOR','ENDUSER','MANUFACTURER','RETAILER'];
      const {
        values,
        touched,
        errors,
        isValid,
        submitError,
        isLoading
      } = this.state;
      const showfullNameError =
        touched.fullName && errors.fullName ? errors.fullName[0] : false;
      const showEmailError =
        touched.email && errors.email ? errors.email[0] : false;
      const showPasswordError =
        touched.password && errors.password ? errors.password[0] : false;
      const showMobileNumberError =
        touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber[0] : false;
      const showcompanyNameError =
        touched.companyName && errors.companyName ? errors.companyName[0] : false;
      const showAddressError =
        touched.address && errors.address ? errors.address[0] : false;
     
    return (
      <DashboardLayout title="Add User">
        <div className={classes.root}>
          <Grid
            container
            justify="center"
            spacing={4}
          >
            <Grid
              item
              lg={12}
              xs={12}
            >
              <Grid
            className={classes.content}
            item
            lg={12}
            xs={12} >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <div className={classes.fields}>
                    {/* <TextField
                        className={classes.textField } 
                        label="Select User Type"
                        margin="dense"
                        onChange={event =>
                          this.handleFieldChange('userType', event.target.value)
                        }
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.userType}
                        variant="outlined" >
                        {roleList.map(option => (
                          <option key={option} value={option} >
                            {option}
                          </option>
                        ))}
                    </TextField> */}
                    <TextField
                      className={classes.textField}
                      label="Full Name*"
                      name="fullName"
                      onChange={event =>
                        this.handleFieldChange('fullName', event.target.value)
                      }
                      value={values.fullName}
                      variant="outlined"
                    />
                    {showfullNameError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.fullName[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label="Email Address*"
                      name="email"
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      value={values.email}
                      variant="outlined"
                    />
                    {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.email[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label="Password*"
                      onChange={event =>
                        this.handleFieldChange('password', event.target.value)
                      }
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.password[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label="Mobile Number*"
                      name="mobileNumber"
                      onChange={event =>
                        this.handleFieldChange('mobileNumber', event.target.value)
                      }
                      value={values.mobileNumber}
                      variant="outlined"
                    />
                    {showMobileNumberError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.mobileNumber[0]}
                      </Typography>
                    )}
                    {/* <TextField
                      className={classes.textField}
                      label="company Name*"
                      onChange={event =>
                        this.handleFieldChange('companyName', event.target.value)
                      }
                      value={values.companyName}
                      variant="outlined"
                    /> */}
                    {/* {showcompanyNameError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.companyName[0]}
                      </Typography>
                    )} */}
                    <TextField
                      className={classes.textField}
                      label="Complete Address*"
                      name="address"
                      onChange={event =>
                        this.handleFieldChange('address', event.target.value)
                      }
                      value={values.address}
                      variant="outlined"
                    />
                    {showAddressError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.address[0]}
                      </Typography>
                    )}
                  
                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant="body2"
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={!isValid}
                      onClick={this.handleAddUser}
                      size="large"
                      variant="contained"
                    >
                      Add User
                    </Button>
                  )}
                </form>
              </div>
            </div>
          </Grid>
            </Grid>
          </Grid>
          <ToastContainer />
        </div>
      </DashboardLayout>
    );
  }
}
AddUser.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object.isRequired
};
export default withStyles(styles)(AddUser);
