import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Grid } from '@material-ui/core';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletContent,
} from 'components';

// Component styles
import styles from './styles';
import moment from 'moment';
import _ from 'lodash';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import { getNotification } from 'services/product';

class Notifications extends Component {

    state = {
        notificationList: [],
    };

    componentDidMount() {
        this.getNotificationList();
    };

    async getNotificationList() {
        try {
            this.setState({ isLoading: true });
            const { notificationList } = await getNotification();
            const sortedNotificationList = _.orderBy(notificationList, (o) => {
                return o.createdAt;
            }, ['desc']);
            this.setState({
                notificationList: sortedNotificationList
            });
        } catch (error) {

        }
    }

    render() {
        const { classes } = this.props;
        const notificationList = this.state.notificationList.slice(0,5);
        return (
            // <DashboardLayout title="Notifications">
            <DashboardLayout title="History">
                {notificationList.map((data, i) =>
                    <Grid md={8} xs={12} className={classes.root}>
                        <Portlet>
                            <PortletHeader className={classes.portletHeader}>
                                <Typography variant="h6" className={classes.deviceIdPortal}>{data.deviceId}</Typography>
                                <Typography variant="body2"
                                    className={classes.createdDate}>{moment(data.createdAt).format('LLLL')}</Typography>
                                {/* <PortletLabel
                      subtitle={moment(data.createdAt).format('LLLL')}
                      title={data.deviceId}
                    /> */}
                            </PortletHeader>
                            <PortletContent className={classes.portletContent}>
                                <Typography variant="body1"
                                    className={classes.portletTypoContent}>{'(' + data.sellerId + ')' + data.description + ((data.purchaserId != data.sellerId) ? "(" + data.purchaserId + ")" : '')}</Typography>
                            </PortletContent>
                        </Portlet>
                    </Grid>
                )}
            </DashboardLayout>
        );
        // return (
        //     <DashboardLayout title="Notifications">
        //         {notificationList.map((data, i) =>
        //             <Grid md={8} xs={12} className={classes.root}>
        //                 <Portlet>
        //                     <PortletHeader className={classes.portletHeader}>
        //                         <Typography variant="h6" className={classes.deviceIdPortal}>{data.deviceId}</Typography>
        //                         <Typography variant="body2"
        //                             className={classes.createdDate}>{moment(data.createdAt).format('LLLL')}</Typography>
        //                         {/* <PortletLabel
        //               subtitle={moment(data.createdAt).format('LLLL')}
        //               title={data.deviceId}
        //             /> */}
        //                     </PortletHeader>
        //                     <PortletContent className={classes.portletContent}>
        //                         <Typography variant="body1"
        //                             className={classes.portletTypoContent}>{data.seller.name + '(' + data.seller.role + ')' + data.description + ((data.purchaserId != data.sellerId) ? data.purchaser.name : '')}</Typography>
        //                     </PortletContent>
        //                 </Portlet>
        //             </Grid>
        //         )}
        //     </DashboardLayout>
        // );
    }
}

Notifications.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Notifications);
