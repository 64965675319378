import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

// Material icons
import {
    PeopleOutlined as PeopleIcon,
    AccountBoxOutlined as AccountBoxIcon,
    SettingsOutlined as SettingsIcon,
    ShoppingBasketOutlined as ShoppingBasketIcon,
    NotificationsOutlined as NotificationsIcon,
    HistoryOutlined as HistoryIcon,
    SearchOutlined as SearchIcon,
    DashboardOutlined as DashboardIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';
import { userProfile } from '../../../../services/user'
import CONFIG from '../../../../services/config';

class Sidebar extends Component {
    state = {
        name: '',
        role: 'ADMIN',
        isLoading: false,
        authType: null
    };

    componentWillMount() {
        let type = localStorage.getItem('authType');
        this.setState({ authType: type });
        // this.getProfile();

    }

    // async getProfile() {
    //     try {
    //         this.setState({ isLoading: true });

    //         let getPro = await userProfile();
    //         if (getPro.success) {
    //             let profile = getPro.info;
    //             this.setState({
    //                 isLoading: false,
    //                 name: profile.name,
    //                 role: profile.role,
    //             });
    //         }
    //     } catch (error) {
    //         if (this.signal) {
    //             this.setState({
    //                 isLoading: false,
    //                 error
    //             });
    //         }
    //     }
    // }

    render() {
        const { classes, className, history } = this.props;
        const rootClassName = classNames(classes.root, className);
        if (sessionStorage.getItem('tabClose') == null) {
            localStorage.clear();
            history.push('/sign-in');
        } else {
            if (!localStorage.getItem('authToken')) {
                history.push('/sign-in');
            }
        }

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper}>
                    {/* <Link
                        className={classes.logoLink}
                        to="/"
                    > */}
                    <img
                        alt="Brainalytica logo"
                        className={classes.logoImage}
                        style={{ height: '60px' }}
                        src="/images/logos/index_logo.png"
                    />
                    {/* </Link> */}
                </div>
                <Divider className={classes.logoDivider} />
                <div className={classes.profile}>
                    {/* <Typography
                        className={classes.nameText}
                        variant="h6"
                    >
                        {CONFIG.COMPANYNAME}
                    </Typography>
                    
                    <Typography
                        className={classes.nameText}
                        variant="h6"
                    >
                        {localStorage.getItem('authName')}
                    </Typography>
                    <Typography
                        className={classes.bioText}
                        variant="caption"
                    >
                        {this.state.role}
                    </Typography> */}
                </div>
                <Divider className={classes.profileDivider} />
                <List
                    component="div"
                    disablePadding>
                    {/* {this.state.authType === 'ADMIN' && (<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard" >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Dashboard"
            />
          </ListItem>)} */}
                    {this.state.authType === 'ADMIN' && (<ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/users">
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Users"
                        />
                    </ListItem>)}
                    {this.state.authType === 'ADMIN' && (<ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/admin-device">
                        <ListItemIcon className={classes.listItemIcon}>
                            <ShoppingBasketIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Device"
                        />
                    </ListItem>)}
                    {this.state.authType != 'ADMIN' && (<ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/device">
                        <ListItemIcon className={classes.listItemIcon}>
                            <ShoppingBasketIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="My Devices"
                        />
                    </ListItem>)}

                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/account">
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Account"
                        />
                    </ListItem>
                    {(this.state.authType !== 'ENDUSER') && (<ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/history">
                        <ListItemIcon className={classes.listItemIcon}>
                            {/* <NotificationsIcon /> */}
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="History"
                        />
                    </ListItem>)}
                    {/* {(this.state.authType !== 'ENDUSER') && (<ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/notification">
                        <ListItemIcon className={classes.listItemIcon}>
                            <NotificationsIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Notifications"
                        />
                    </ListItem>)} */}
                    {/*{this.state.authType !== 'ADMIN' && (<ListItem*/}
                    {/*activeClassName={classes.activeListItem}*/}
                    {/*className={classes.listItem}*/}
                    {/*component={NavLink}*/}
                    {/*to="/search-device">*/}
                    {/*<ListItemIcon className={classes.listItemIcon}>*/}
                    {/*<SearchIcon/>*/}
                    {/*</ListItemIcon>*/}
                    {/*<ListItemText*/}
                    {/*classes={{primary: classes.listItemText}}*/}
                    {/*primary="Search Device"*/}
                    {/*/>*/}
                    {/*</ListItem>)}*/}
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/settings">
                        <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Settings"
                        />
                    </ListItem>
                </List>
                {/* <Divider className={classes.listDivider} /> */}

            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(Sidebar);
