import React, { Component } from 'react';
import { Switch, Route, HashRouter as Router, Redirect } from 'react-router-dom';

// Views
import Dashboard from './views/Dashboard';
import UserList from './views/UserList';
import AddUser from './views/AddUser';

import Account from './views/Account';
import Settings from './views/Settings';
import ForgotPassword from './views/ForgotPassword';
import NewPassword from './views/NewPassword';

import DeviceList from './views/DeviceList';
import DeviceAdd from './views/DeviceList/DeviceAdd';
import DeviceDetail from './views/DeviceList/DeviceDetail';
import SearchDevice from './views/SearchDevice';
import AdminDeviceList from './views/AdminDeviceList';


import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import NotFound from './views/NotFound';
import Notification from './views/Notifications';
import AdminDashboard from './views/AdminDashboard';

export default class Routes extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/device"
          />
          <Route
            component={AdminDashboard}
            exact
            path="/dashboard"
          />
          <Route
            component={UserList}
            exact
            path="/users"
          />
          <Route
            component={AddUser}
            exact
            path="/add-user"
          />
          <Route
            component={DeviceList}
            exact
            path="/device"
          />
          <Route
            component={AdminDeviceList}
            exact
            path="/admin-device"
          />
          <Route
            component={SearchDevice}
            exact
            path="/search-device"
          />
          <Route
            component={DeviceAdd}
            exact
            path="/add-device"
          />
          {/* <Route
            component={DeviceDetail}
            exact
            path="/device-detail/:transactionId"
          /> */}
          <Route
            component={DeviceDetail}
            exact
            path="/device-detail/:transactionId/:all_purchase_sold"
          />
          <Route
            component={Account}
            exact
            path="/account"
          />
          <Route
            component={Settings}
            exact
            path="/settings"
          />
          <Route
            component={ForgotPassword}
            exact
            path="/forgot-password"
          />
          <Route
            component={NewPassword}
            exact
            path="/new-password/:userId"
          />
          <Route
            component={SignIn}
            exact
            path="/sign-in"
          />
          <Route
            component={SignUp}
            exact
            path="/sign-up"
          />
          {/* <Route
            component={Notification}
            exact
            path="/notification"
          /> */}
          <Route
            component={Notification}
            exact
            path="/history"
          />
          <Route
            component={NotFound}
            exact
            path="/not-found"
          />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    );
  }
}
