import { AuthToken } from 'services/common';
import CONFIG from '../config';
import axios from 'axios';
import PropTypes from 'prop-types';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('POC');


function objectEmpty(data) {
    return !Object.keys(data).length;
}

// export const signIn = (email, password) => {
//     return new Promise((resolve, reject) => {
//         let logindata = {
//             email: email,
//             password:cryptr.encrypt(password)
//             // password: btoa(password)

//         }
//         // console.log(logindata);
//         axios.post(CONFIG.APIURL + 'user/login', logindata, {headers: AuthToken.setHeader()})
//             .then(res => {
//                 let user = res.data;
//                 if (!objectEmpty(user) && user.code === 200) {
//                     sessionStorage.setItem('tabClose', true);
//                     if (AuthToken.setAuthenicationToken(user.data)) {
//                         resolve({success: true, message: user.message});
//                     }
//                 } else {
//                     resolve({success: false, message: user.message});
//                 }
//             }).catch(err => {
//             reject({
//                 error: err.response !== undefined ? err.response.data.err : "Server error"
//             });
//         });
//     });
// };
export const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
        let logindata = {
            email: email,
            password: cryptr.encrypt(password),
            // password: btoa(password),
            companyid: CONFIG.COMPANYNAME

        }
        // console.log(logindata);
        axios.post(CONFIG.APIURL + 'user/login', logindata, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (!objectEmpty(user) && user.code === 200) {
                    sessionStorage.setItem('tabClose', true);
                    if (AuthToken.setAuthenicationToken(user.data)) {
                        resolve({ success: true, message: user.message });
                    }
                } else {
                    resolve({ success: false, message: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const signUp = (userdata) => {
    return new Promise((resolve, reject) => {
        // userdata.password = btoa(userdata.password)
        userdata.password = cryptr.encrypt(userdata.password);
        setTimeout(() => {
            axios.post(CONFIG.APIURL + 'user/register', userdata, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (user.code === 201) {
                        // AuthToken.setAuthenicationToken(user.data)
                        resolve({ success: true });
                    } else {
                        resolve({ success: false, error: user.message });
                    }
                }).catch(err => {
                    reject({
                        error: err.response !== undefined ? err.response.data.err : "Server error"
                    });
                });
        }, 500);
    });
};
export const addUser = (userdata) => {
    return new Promise((resolve, reject) => {
        // userdata.password = btoa(userdata.password);
        userdata.password = cryptr.encrypt(userdata.password);
        setTimeout(async () => {
            // let idealTime = await AuthToken.getIdleTimeLogout();
            // idealTime === true ? AuthToken.setIdleTimeLogout() : this.props.push('/sign-in');
            axios.post(CONFIG.APIURL + 'user/create', userdata, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (user.code === 201) {
                        resolve({ success: true });
                    } else {
                        resolve({ success: false, error: user.message });
                    }
                }).catch(err => {
                    reject({
                        error: err.response !== undefined ? err.response.data.err : "Server error"
                    });
                });
        }, 500);
    });
};
export const userProfile = (userdata) => {
    return new Promise((resolve, reject) => {
        axios.get(CONFIG.APIURL + 'user/profile', { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (!objectEmpty(user.data) && user.code === 201) {
                    resolve({ success: true, info: user.data });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const updateProfile = (userdata) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'user/updateProfile', userdata, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (!objectEmpty(user.data) && user.code === 204) {
                    resolve({ success: true, data: user.data });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const getUsers = (currentPage, limit, searchText) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let pageNo = (currentPage + 1);
            axios.get(CONFIG.APIURL + 'user/list?pageNo=' + pageNo + '&limit=' + limit + '&searchText=' + searchText + '&companyid=' + CONFIG.COMPANYNAME, { headers: AuthToken.setHeader() })
                .then(res => {
                    let user = res.data;
                    if (!objectEmpty(user) && user.code === 200) {
                        resolve({
                            users: user.data.list || [],
                            usersTotal: user.data.totalPage || 0
                        });
                    } else {
                        resolve({ users: [], usersTotal: 0, error: user.message });
                    }
                }).catch(err => {
                    resolve({ users: [], usersTotal: 0, error: err });
                });
        }, 500);
    });
};
export const deleteUsers = (users) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'user/delete', { _id: users }, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 205) {
                    resolve({ success: true, data: user.data });
                } else {
                    resolve({ users: [], usersTotal: 0, error: user.message });
                }
            }).catch(err => {
                resolve({ users: [], usersTotal: 0, error: err });
            });
    });
};
export const changePassword = (userdata) => {
    return new Promise((resolve, reject) => {
        let password = {
            // newPassword: btoa(userdata.newPassword),
            // oldPassword: btoa(userdata.oldPassword)
            newPassword: cryptr.encrypt(userdata.newPassword),
            oldPassword: cryptr.encrypt(userdata.oldPassword)
        };
        axios.post(CONFIG.APIURL + 'user/changePassword', password, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 204) {
                    resolve({ success: true });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const newPassword = (data) => {
    return new Promise((resolve, reject) => {
        // data.password = btoa(data.password);
        data.password = cryptr.encrypt(data.password);
        axios.post(CONFIG.APIURL + 'user/newPassword', data, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 204) {
                    resolve({ success: true, data: user.data });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        //let new Token =await refershToken();
        axios.post(CONFIG.APIURL + 'user/forgotPassword', { email: email }, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 200) {
                    resolve({ success: true, info: user.data });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const userBlockUnBlock = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'user/updateUserStatus', data, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 204) {
                    resolve({ success: true, info: user.data.nModified });
                } else {
                    resolve({ success: false, error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};

function refershToken() {
    let hdr = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('refreshToken')
    };
    axios.get(CONFIG.APIURL + 'user/refershToken', { headers: hdr })
        .then(res => {
            let user = res.data;
            if (user.code === 200) {
                return
            } else {
                return
            }
        })
}

export const getParentSellers = (uid) => {
    return new Promise((resolve, reject) => {
        axios.post(CONFIG.APIURL + 'user/parentSellerList', { uid }, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 200) {
                    resolve({ success: true, list: user.data });
                } else {
                    resolve({ success: false, list: [], error: user.message });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};
export const getLastOwner = (uid) => {
    return new Promise((resolve, reject) => {
        const companyid = CONFIG.COMPANYNAME;
        axios.post(CONFIG.CHAINAPIURL + 'user/getLastOwner', { uid, companyid }, { headers: AuthToken.setHeader() })
            .then(res => {
                let user = res.data;
                if (user.code === 200) {
                    resolve({ success: true, list: user.data, lastpurchaseId: user.lastpurchaseId});
                } else {
                    resolve({ success: false, list: [], error: user.message, lastpurchaseId:[] });
                }
            }).catch(err => {
                reject({
                    error: err.response !== undefined ? err.response.data.err : "Server error"
                });
            });
    });
};