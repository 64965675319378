import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Badge,
  Checkbox,
  Table,
    Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

class UsersTable extends Component {
  state = {
    selectedUsers: [],
    users: this.props.users,
    // sort: {
    //   column: null,
    //   direction: 'desc',
    // },
    sort: {
      order: 1,
      type: 'name'
    }
  };
  handleSelectAll = event => {
    const { users, onSelect } = this.props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user._id);
    } else {
      selectedUsers = [];
    }

    this.setState({ selectedUsers });

    onSelect(selectedUsers);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedUsers } = this.state;

    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedUsers: newSelectedUsers });

    onSelect(newSelectedUsers);
  };

  handleChangePage = (event, page) => {
    this.props.onPageChange(page, this.props.rowsPerPage);
  };

  handleChangeRowsPerPage = event => {
    this.props.onPageChange(this.props.page, event.target.value);
  };
  updateUsersStatus = (user, index) => {
    this.props.onUpdateUser(user, index, this)
  }

  createSortHandler(row) {
    let newSortParams = { ...this.state.sort };
    if (this.state.sort.type === row) {
      newSortParams.order = this.state.sort.order === 0 ? 1 : 0;
    } else {
      newSortParams.type = row;
    }
    let sortOrder = newSortParams.order === 0 ? 'asc' : 'desc';
    let users = _.orderBy(this.props.users, [row], [sortOrder]);
    this.setState({
      sort: newSortParams,
      users: users,
      order: sortOrder
    });
  }

  render() {
    console.log("user", this.state.users);
    const { classes, className, users, usersTotal, rowsPerPage, page, onSearch } = this.props;
    const { activeTab, selectedUsers, order } = this.state;

    const rootClassName = classNames(classes.root, className);
    const lowercasedFilter = onSearch.toLowerCase();

    const headerRow = [
      { id: 'name', label: 'Name' },
      { id: 'email', label: 'Email' },
      // { id: 'company', label: 'Company' },
      { id: 'lastLoginDttm', label: 'Last Login' },
      // { id: 'role', label: 'Role' }
    ]
    let { onSelectAllClick, orderBy, numSelected, rowCount, setOrder, setOrderBy, onRequestSort, handleRequestSort } = this.props;
    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>

          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    headerRow.map((user, index) => (
                      <TableCell align="left" className={classes.tableCell}>
                        {user.id === 'name' && (
                          <Checkbox align="left"
                            checked={selectedUsers.length === users.length}
                            color="primary"
                            indeterminate={
                              selectedUsers.length > 0 &&
                              selectedUsers.length < users.length
                            }
                            onChange={this.handleSelectAll}
                          />
                        )}
                        <TableSortLabel
                          direction={order}
                          onClick={() => this.createSortHandler(user.id)}
                        >{user.label}</TableSortLabel>
                      </TableCell>
                    ))
                  }
                  <TableCell align="left">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users
                  .map((user, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user._id}
                      selected={selectedUsers.indexOf(user._id) !== -1}
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={selectedUsers.indexOf(user._id) !== -1}
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, user._id)
                            }
                            value="true"
                          />

                          <Link to="#">
                            <Typography
                              className={classes.nameText}
                              variant="body1"
                            >
                              {user.name}
                            </Typography>
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.email}
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {user.company}
                      </TableCell> */}
                      <TableCell className={classes.tableCell}>
                        {user.lastLoginDttm ? user.lastLoginDttm.replace('GM:+0000 (Coordinated Universal Time)','UTC') : 'Has not logged in'}
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {user.role}
                      </TableCell> */}
                      <TableCell className={classes.tableCell}>
                        {user.isActive ?
                          <Badge color="secondary" className={classes.badgeSuccess}
                            onClick={() => this.updateUsersStatus(user, index)}
                          >
                            BLOCK
                          </Badge> :
                          <Badge color="secondary" className={classes.badgeDanger}
                            onClick={() => this.updateUsersStatus(user, index)}
                          >
                            UNBLOCK
                          </Badge>}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
              className={classes.paginationButton}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            component="div"
            count={usersTotal}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
              classes={{
                  actions: classes.tablePaginationActions,
                  selectRoot:classes.tablePaginationSelectRoot
              }}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

UsersTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired,
  usersTotal: PropTypes.number,
  onPageChange: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onSearch: PropTypes.string

};

UsersTable.defaultProps = {
  users: [],
  usersTotal: 0,
  rowsPerPage: 5,
  page: 0,
  onSearch: '',
  order: '',
  onPageChange: () => { },
  onSelect: () => { },
  onShowDetails: () => { },
  onUpdateUser: () => { }
};

export default withStyles(styles)(UsersTable);



