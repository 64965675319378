import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import moment from 'moment';

// Material components
import {
    IconButton, Button,
    Grid,
    Typography,
    withStyles,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Stepper,
    Step,
    StepLabel,
    StepConnector
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { Portlet, PortletContent } from 'components';
import ModalDialog from 'components/ModalDialog';
import HorizontalScroll from 'react-scroll-horizontal'

import PerfectScrollbar from 'react-perfect-scrollbar';
import "./../../../assets/scss/styles.css";
import { Close as CloseIcon } from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';

import { getDeviceDetails, getDistributor, sellDistributor, getHistory } from 'services/product';

import { Toast } from 'services/common';
import { ToastContainer } from 'react-toastify';

import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import styles from './styles';

class DeviceDetail extends Component {
    signal = true;

    state = {
        isLoading: false,
        deviceInfo: { deviceDetail: {}, purchaserList: [], history: [] },
        distributorList: [],
        selectedDistributor: '',
        error: null,
        transactionId: null,
        all_purchase_sold: null,
        open: false
    };
    componentWillMount() {
        const authType = localStorage.getItem('authType');
        this.setState({
            transactionId: this.props.match.params.transactionId,
            all_purchase_sold: this.props.match.params.all_purchase_sold,

            authType: authType
        });
        this.getDevicesInfo();
        // this.getDistributorList();

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleSellDevive = this.handleSellDevive.bind(this);
    }
    // componentWillMount() {
    //     const authType = localStorage.getItem('authType');
    //     this.setState({
    //         transactionId: this.props.match.params.transactionId,
    //         authType: authType
    //     });
    //     this.getDevicesInfo();
    //     this.getDistributorList();

    //     this.handleFieldChange = this.handleFieldChange.bind(this);
    //     this.handleSellDevive = this.handleSellDevive.bind(this);
    // }
    async getDevicesInfo() {
        try {
            let transactionId = this.state.transactionId ? this.state.transactionId : this.props.match.params.transactionId
            let all_purchase_sold = this.state.all_purchase_sold ? this.state.all_purchase_sold : this.props.match.params.all_purchase_sold

            const { deviceInfo } = await getDeviceDetails(transactionId, all_purchase_sold);
            this.setState({
                deviceInfo
            });
        } catch (error) {
        }
    }
    // async getDevicesInfo() {
    //     try {
    //         let transactionId = this.state.transactionId ? this.state.transactionId : this.props.match.params.transactionId
    //         const { deviceInfo } = await getDeviceDetails(transactionId);
    //         this.setState({
    //             deviceInfo
    //         });
    //     } catch (error) {
    //     }
    // }

    async getDistributorList() {
        try {
            this.setState({ isLoading: true });
            let sellDevice = await getDistributor();
            if (sellDevice.success === true) {
                let devicePurchaser = sellDevice.list;

                this.setState({
                    distributorList: devicePurchaser,
                    isLoading: false,
                });
                let purchaserIds = [];
                const deviceInfo = this.state.deviceInfo;
                deviceInfo.purchaserList.filter(history => {
                    purchaserIds.push(history['id']);
                });

                let purchaseDeviceUsers = [];
                sellDevice.list.filter(userList => {
                    if (purchaserIds.indexOf(userList['_id']) === -1) {
                        purchaseDeviceUsers.push(userList);
                    }
                });
                devicePurchaser = purchaseDeviceUsers;
                this.setState({
                    distributorList: devicePurchaser,
                    isLoading: false,
                });
            } else {
                Toast.errorToast(sellDevice.error);
                this.setState({
                    isLoading: false,
                    serviceError: sellDevice.error
                });
            }
        } catch (error) {
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleFieldChange(feild, value) {
        this.setState({
            [feild]: value
        });
    }

    async handleSellDevive() {
        try {
            this.setState({ isLoading: true });
            let sellDevice = await sellDistributor({
                transactionId: this.props.match.params.transactionId,
                purchaserId: this.state.selectedDistributor,
                manufacturerId: this.state.deviceInfo.deviceDetail.userId,
                deviceId: this.state.deviceInfo.deviceDetail.deviceId
            });

            if (sellDevice.success === true) {
                Toast.successToast("sell device successfully");
                this.setState({
                    isLoading: false,
                    open: false,
                    serviceError: sellDevice.error
                });
                this.getDevicesInfo();

            } else {
                Toast.errorToast(sellDevice.error);
                this.setState({
                    isLoading: false,
                    serviceError: sellDevice.error
                });
            }
        } catch (error) {
        }
    }

    //Set Images for Device History Life Cycle
    libStepIcon = (props) => {

        const { classes } = this.props;
        const { deviceInfo } = this.state;
        let icons = [];

        for (let i = 0; i <= deviceInfo.history.length; i++) {
            i === 1 ?
                icons.push(<img src="/images/img/orders.png" alt="order icon" className={classes.imageicon} />) :
                icons.push(<img src="/images/img/delivery.png" alt="order icon" className={classes.imageicon} />)
        }
        return (
            <div>
                {icons[String(props.icon)]}
            </div>
        );
    }

    //Device History Life Cycle
    _renderDeviceHistory() {
        const { classes } = this.props;
        const { deviceInfo } = this.state;
        console.log(deviceInfo);
        return (
            <div className={classes.historyroot}>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.bold}>Device History Lifecycle:</Typography>
                </Grid>
                <div className={classes.mTop2}></div>
                <Stepper orientation="vertical" connector={<QontoConnector />}>
                    {deviceInfo.history.map((history, index) => (
                        <Step key={index} className={classes.stepperSteps}>
                            <StepLabel StepIconComponent={this.libStepIcon}>
                                <Typography variant="body1" className={classes.stepperTitle}>
                                    {/* {history.sellerName + ' (' + history.sellerRole + ')' + history.description + ((history.purchaserRole != 'MANUFACTURER') ? history.purchaserName : '') + ((history.purchaserRole != 'MANUFACTURER') ? (' (' + history.purchaserRole + ')') : '')} */}
                                    {' (' + history.sellerId + ')'}

                                    {history.description}
                                    {
                                        history.manufactureId != history.purchaserId ? history.purchaserId : null
                                    }

                                </Typography>
                                <Typography variant="caption" className={classes.captiontext}>
                                    {moment(history.createdAt).format('DD/MM/YYYY hh:mm')}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        );
        // return (
        //     <div className={classes.historyroot}>
        //         <Grid item xs={12}>
        //             <Typography variant="body1" className={classes.bold}>Device History Lifecycle:</Typography>
        //         </Grid>
        //         <div className={classes.mTop2}></div>
        //         <Stepper orientation="vertical" connector={<QontoConnector/>}>
        //             {deviceInfo.history.map((history, index) => (
        //                 <Step key={index} className={classes.stepperSteps}>
        //                     <StepLabel StepIconComponent={this.libStepIcon}>
        //                         <Typography variant="body1" className={classes.stepperTitle}>
        //                             {history.sellerName + ' (' + history.sellerRole + ')' + history.description + ((history.purchaserRole != 'MANUFACTURER') ? history.purchaserName : '') + ((history.purchaserRole != 'MANUFACTURER') ? (' (' + history.purchaserRole + ')') : '')}
        //                         </Typography>
        //                         <Typography variant="caption" className={classes.captiontext}>
        //                             {moment(history.createdAt).format('DD/MM/YYYY hh:mm')}
        //                         </Typography>
        //                     </StepLabel>
        //                 </Step>
        //             ))}
        //         </Stepper>
        //     </div>
        // );
    }
    gotoDevices() {
        const { history } = this.props;
        history.push('/device');
    }
    //Modal Dialog
    _renderSoldDialog() {
        const { classes } = this.props;
        const { distributorList, selectedDistributor, deviceInfo } = this.state;

        const isValid = selectedDistributor == '' ? false : true;
        return (
            <ModalDialog
                open={this.state.open}
                distributorList={distributorList}
                deviceInfo={deviceInfo}
                handleFieldChange={this.handleFieldChange}
                handleSellDevive={this.handleSellDevive}
            />
        );
    }
    // check object is empty or not
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    render() {
        const { classes, history } = this.props;
        const { deviceInfo, authType } = this.state;
        const authId = localStorage.getItem('authId');
        const authEmail = localStorage.getItem('authEmail');
        let ackSellDevice, distributorTypography, retailerTypography, doctorTypography = false;
        //Display DISTRIBUTOR, RETAILER, DOCTOR grids based on flag
        //Disable and Enable Sell Button based on flag
        if (deviceInfo.purchaserList) {
            {
                deviceInfo.purchaserList.map((user, index) => (
                    ackSellDevice = (user == "" || user.email == authEmail) ? true : false,
                    distributorTypography = user.role === 'DISTRIBUTOR' || distributorTypography ? true : false,
                    retailerTypography = user.role === 'RETAILER' || retailerTypography ? true : false,
                    doctorTypography = user.role === 'DOCTOR' || doctorTypography ? true : false
                ))
            }
        }

        if (!localStorage.getItem('authToken')) {
            history.push('/sign-in');
        }
        let deviceFullInfo = {}
        if (authType === 'MANUFACTURER' || authType === 'ADMIN') {
            deviceFullInfo = deviceInfo.deviceDetail;
        } else {
            if (deviceInfo.deviceDetail.UDI) {
                deviceFullInfo = deviceInfo.deviceDetail ? deviceInfo.deviceDetail : {};
                localStorage.searchDevice == "true" ?
                    ackSellDevice = false : ackSellDevice = true

            } else {
                deviceFullInfo = deviceInfo.deviceDetail.deviceDetails ? deviceInfo.deviceDetail.deviceDetails[0] : {};
            }

        }
        let Expiration_Date = '';
        if(!this.isEmpty(deviceFullInfo)){
            var splitted_udi = deviceFullInfo.UDI.split('(17)');
            var splitted_expiration_date_lot_number = splitted_udi[1].split('(10)');
            var day = moment(splitted_expiration_date_lot_number[0], "YYMMDD");
            Expiration_Date = day.format("YYYY-MM-DD");
        }

        return (
            <DashboardLayout title="Device Information">
                <div className={classes.root}>
                    <Portlet>
                        <PortletContent className={classes.themePadding2}>

                            {/*{(ackSellDevice && (authType != 'ADMIN' && authType != 'ENDUSER')) ?*/}
                            {/*<Button*/}
                            {/*className={classes.soldButton}*/}
                            {/*color="primary"*/}
                            {/*onClick={this.handleClickOpen}*/}
                            {/*variant="outlined"*/}
                            {/*size="large">*/}
                            {/*SELL*/}
                            {/*</Button> : ''}*/}
                            {this._renderSoldDialog()}

                            <div className={classes.group}>

                                {/* {authType != 'MANUFACTURER' && deviceInfo.manufacturerDetail ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.bold}>Manufacturer
                                                Details:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Manufacturer ID</Typography>
                                            <Typography
                                                variant="caption">{deviceInfo.manufacturerDetail['id']}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Manufacturer Name</Typography>
                                            <Typography
                                                variant="caption">{deviceInfo.manufacturerDetail['name']}</Typography>
                                        </Grid>
                                    </Grid> : null} */}

                                {(authType === 'RETAILER' || authType === 'DOCTOR' || authType === 'ENDUSER') && distributorTypography ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.bold}>Distributor
                                                Details:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Distributor ID</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'DISTRIBUTOR' ?
                                                    <Typography variant="caption">{user.id}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Distributor Name</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'DISTRIBUTOR' ?
                                                    <Typography variant="caption">{user.name}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                    </Grid> : null}

                                {(authType === 'DOCTOR' || authType === 'ENDUSER') && retailerTypography ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.bold}>Retailer
                                                Details:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Retailer ID</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'RETAILER' ?
                                                    <Typography variant="caption">{user.id}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Retailer Name</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'RETAILER' ?
                                                    <Typography variant="caption">{user.name}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                    </Grid> : null}

                                {authType === 'ENDUSER' && doctorTypography ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.bold}>Doctor
                                                Details:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Doctor ID</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'DOCTOR' ?
                                                    <Typography variant="caption">{user.id}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1">Doctor Name</Typography>
                                            {deviceInfo.purchaserList.map((user, index) => (
                                                <div>{user.role === 'DOCTOR' ?
                                                    <Typography variant="caption">{user.name}</Typography>
                                                    : null}</div>
                                            ))}
                                        </Grid>
                                    </Grid> : null}

                                <Grid container spacing={3}>
                                    <Grid item xs={10}>
                                        <Typography variant="body1" className={classes.bold}>Device Details</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            onClick={() => this.gotoDevices()}>
                                            My Devices
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">UDI Number</Typography>
                                        <Typography variant="caption">{deviceFullInfo.UDI}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Lot Number (GUDID)</Typography>
                                        <Typography variant="caption">{deviceFullInfo.lotNumber || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Expiration Date</Typography>
                                        <Typography variant="caption">{Expiration_Date || '--'}</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12}>
                      <Typography variant="body1">510k Number</Typography>           
                      <div className={classes.scrollWidth}>
                        <PerfectScrollbar style={{}}>
                          {deviceFullInfo['FOZk'] ?
                            deviceFullInfo['FOZk'].map((kNumber) => (
                              <Typography variant="caption">{kNumber || '--'}<br/></Typography>
                            ))
                          :
                            <Typography variant="caption">{'--'}</Typography>
                          }
                        </PerfectScrollbar>
                      </div>                              
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body1">FDA Registration Number</Typography>
                      <div className={classes.scrollWidth}>
                        <PerfectScrollbar>
                            {deviceFullInfo.registrationNumber ?
                              deviceFullInfo.registrationNumber.map((rNumber) => (
                                <Typography variant="caption">{rNumber || '--'}<br/></Typography>
                              ))
                            :
                              <Typography variant="caption">{'--'}</Typography>
                            }
                        </PerfectScrollbar>
                      </div>
                    </Grid> */}
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Company Name</Typography>
                                        <Typography variant="caption">{deviceFullInfo.companyName || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">510k Number</Typography>
                                        <Typography variant="caption">{deviceFullInfo['FOZk'] || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">FDA Registration Number</Typography>
                                        <Typography
                                            variant="caption">{deviceFullInfo.registrationNumber || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Product Code</Typography>
                                        <Typography
                                            variant="caption">{deviceFullInfo.registrationName || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Device Name</Typography>
                                        <Typography variant="caption">{deviceFullInfo.deviceName}</Typography>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6}>*/}
                                    {/*<Typography variant="body1">Device ID</Typography>*/}
                                    {/*<Typography variant="caption">{deviceFullInfo.deviceId}</Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Sterilization</Typography>
                                        <Typography variant="caption">{deviceFullInfo.versionModelNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Device Count (per lot)</Typography>
                                        <Typography variant="caption">{deviceFullInfo.deviceCount}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Brand Name</Typography>
                                        <Typography variant="caption">{deviceFullInfo.brandName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Regulation Number</Typography>
                                        <Typography
                                            variant="caption">{deviceFullInfo.regulationNumber || '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1">Specifications</Typography>
                                        <Typography variant="caption">
                                            Version or Model -> {deviceFullInfo.versionModelNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">Device Description</Typography>
                                        <Typography variant="caption">{deviceFullInfo.deviceDescription}</Typography>
                                    </Grid>
                                </Grid>
                                {this._renderDeviceHistory()}
                                {(deviceInfo.purchaserList && deviceInfo.purchaserList != '') ?
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={classes.bold}>Sold Distributor
                                            Details</Typography>
                                        <PerfectScrollbar>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {/*<TableCell align="left">Id</TableCell>*/}
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="left">Email</TableCell>
                                                        <TableCell align="left">CreatedAt</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {deviceInfo.purchaserList && (<TableBody>
                                                    {deviceInfo.purchaserList.map((user, index) => (
                                                        <TableRow className={classes.tableRow} hover key={user.id}>
                                                            {/*<TableCell*/}
                                                            {/*className={classes.tableCell}>{user.id}</TableCell>*/}
                                                            <TableCell
                                                                className={classes.tableCell}>{user.name}</TableCell>
                                                            <TableCell
                                                                className={classes.tableCell}>{user.email}</TableCell>
                                                            <TableCell className={classes.tableCell}>
                                                                {user.createdAt ? moment(user.createdAt).format('DD/MM/YYYY') : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>)}
                                            </Table>
                                        </PerfectScrollbar>
                                    </Grid> : ''}
                            </div>
                        </PortletContent>
                    </Portlet>
                </div>
                <ToastContainer />
            </DashboardLayout>
        );
    }
}

DeviceDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceDetail);
const style = (theme: Theme) => {
    createStyles({
        root: {
            width: '100% !important',
            border: '1px solid #00BFFF',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
            color: theme.palette.grey[500],
        },
    });
}
export
interface
DialogTitleProps
extends
WithStyles < typeof styles > {}
const DialogTitle = withStyles(styles)((props = { this: DialogTitleProps }) => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        margin: '0px 50px 0px 0px',
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({}))(MuiDialogActions);

const QontoConnector = withStyles({
    line: {
        borderColor: '#9A7D0A',
        borderWidth: 2,
        minHeight: 30
    }
})(StepConnector);

