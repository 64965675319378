import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
    IconButton,
    CircularProgress,
    Grid,
    Typography,
    TablePagination,
    Tooltip
} from '@material-ui/core';

// Material icons
import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getDevices, getPurchaseDevices, getSoldDevices } from 'services/product';

// Custom components
import { DeviceToolbar, DeviceCard } from './components';
import { Toolbar } from 'components';

// Component styles
import styles from './styles';

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import CONFIG from '../../services/config'

class DeviceList extends Component {
    signal = true;
    // all_purchase_sold 0 for All products tab, 1 for Purchase Products tab, 2 for Sold Products tab
    state = {
        isLoading: false,
        currentPage: 0,
        limit: 6,
        devices: [],
        devicesTotal: 0,
        error: null,
        searchText: '',
        value: 0,
        all_purchase_sold: 0
    };
    async getDevices(currentPage, limit, searchText) {
        try {
            this.setState({ isLoading: true });
            searchText = searchText == undefined ? '' : searchText;
            const authType = localStorage.getItem('authType');
            const all_purchase_sold = this.state.all_purchase_sold;
            // show All Products tab
            if (all_purchase_sold == 0) {
                var { devices, devicesTotal } = await getDevices(currentPage, limit, searchText, CONFIG.COMPANYNAME);
            }
            // show Purshased Products tab
            else if (all_purchase_sold == 1) {
                var { devices, devicesTotal } = await getPurchaseDevices(currentPage, limit, false, searchText, CONFIG.COMPANYNAME);

            }
            //  show Sold Products tab
            else if (all_purchase_sold == 2) {
                var { devices, devicesTotal } = await getPurchaseDevices(currentPage, limit, true, searchText, CONFIG.COMPANYNAME);

            }
            if (devices) {
                // console.log("devices=======================", devices)
                if (this.signal) {
                    this.setState({
                        isLoading: false,
                        devices,
                        devicesTotal,
                        limit
                    });
                }
            }

        } catch (error) {
            if (this.signal) {
                this.setState({
                    isLoading: false,
                    error
                });
            }
        }
    }
    // async getDevices(currentPage, limit, searchText) {
    //     try {
    //         this.setState({isLoading: true});
    //         searchText = searchText == undefined ? '' : searchText;
    //         const authType = localStorage.getItem('authType');
    //         const ackSoldDevice = this.state.ackSoldDevice;
    //         // if(authType === 'MANUFACTURER'){
    //         //   var { devices, devicesTotal } = await getDevices(currentPage,limit,searchText);
    //         // }else{

    //         //   var { devices, devicesTotal } = await getPurchaseDevices(currentPage,limit,ackSoldDevice,searchText);
    //         // }
    //         const {devices, devicesTotal} = authType === 'MANUFACTURER' ? await getDevices(currentPage, limit, searchText) : await getPurchaseDevices(currentPage, limit, ackSoldDevice, searchText)

    //         if (devices) {
    //             console.log("devices=======================", devices)
    //             if (this.signal) {
    //                 this.setState({
    //                     isLoading: false,
    //                     devices,
    //                     devicesTotal,
    //                     limit
    //                 });
    //             }
    //         }

    //     } catch (error) {
    //         if (this.signal) {
    //             this.setState({
    //                 isLoading: false,
    //                 error
    //             });
    //         }
    //     }
    // }

    componentWillMount() {
        this.signal = true;
        let type = localStorage.getItem('authType');
        this.setState({ authType: type });
        const { currentPage, limit, searchText } = this.state;

        this.getDevices(currentPage, limit, searchText);
    }

    componentWillUnmount() {
        this.signal = false;
    }

    handleChangeRowsPerPage = event => {
        this.setState({ limit: event.target.value });
        this.getDevices(this.state.currentPage, event.target.value);
    };
    handleChangePage = (event, page) => {
        this.setState({ currentPage: page });
        this.getDevices(page, this.state.limit);
    };

    onAddDevice() {
        const { history } = this.props;
        history.push('/add-device');
    }

    handleSearch = (searchText, clean) => {
        this.getDevices(0, this.state.limit, searchText);
        this.setState({ currentPage: 0, searchText: searchText });
    }
    handleChange = async (event, value) => {
        await this.setState({ value: value, all_purchase_sold: value })
        this.getDevices(0, this.state.limit);
    };

    // handleChange = async (event, value) => {
    //     let ackSoldDevice = false;
    //     if (value === 1) {
    //         ackSoldDevice = true;
    //     }
    //     await this.setState({ value, all_purchase_sold });
    //     this.getDevices(0, this.state.limit);
    // };

    renderDevices() {
        const { classes } = this.props;
        const { isLoading, devices, searchText } = this.state;
        if (isLoading) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress />
                </div>
            );
        }

        if (devices.length === 0) {
            return (
                <Typography variant="h6">There are no Devices available</Typography>
            );
        }

        return (
            <Grid
                container
                spacing={3}
            >
                {devices.map(device => (
                    <Grid
                        item
                        key={device.id}
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        {/*<Link to="#">*/}
                        {/* <DeviceCard device={device} onSearch={searchText} ackSoldDevice={this.state.ackSoldDevice} /> */}

                        <DeviceCard device={device} onSearch={searchText} all_purchase_sold={this.state.all_purchase_sold} />
                        {/*</Link>*/}
                    </Grid>
                ))}
            </Grid>
        );
    }

    render() {
        const { classes } = this.props;
        const { devices, currentPage, limit, devicesTotal, searchText, authType, ackSoldDevice } = this.state;
        let ackAddButton = true;
        // if (authType != 'ENDUSER' && ackSoldDevice === false) {
        //     ackAddButton = true;
        // }
        return (
            <DashboardLayout title="My Devices">

                <div className={classes.root}>

                    <Toolbar onAdd={() => this.onAddDevice()}
                        onSearch={this.handleSearch}
                        deleteButton={false}
                        addButton={ackAddButton}
                    />

                    {/* {authType != 'ENDUSER' && authType != 'MANUFACTURER' ?
                        <Paper square className={classes.content}>
                            <Tabs
                                variant="fullWidth"
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                classes={{
                                    flexContainer: classes.displayTabs
                                }}
                            >

                                <Tab label="Purchase Products" />
                                <Tab label="Sold Products" />
                            </Tabs>
                        </Paper>
                        : ''} */}

                    <Paper square className={classes.content}>
                        <Tabs
                            variant="fullWidth"
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{
                                flexContainer: classes.displayTabs
                            }}
                        >
                            <Tab label="Your Products"/>
                            <Tab label="Purchased Products"/>
                            <Tab label="Sold Products"/>
                        </Tabs>
                    </Paper>

                    <div className={classes.content}>{this.renderDevices()}</div>
                    <div className={classes.pagination}>
                        <TablePagination
                            backIconButtonProps={{
                                'aria-label': 'Previous Page'
                            }}
                            component="div"
                            count={devicesTotal}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page'
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            page={currentPage}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[3, 6, 9]}
                            classes={{
                                actions: classes.tablePaginationActions,
                                selectRoot: classes.tablePaginationSelectRoot,
                            }}
                        />
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

DeviceList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceList);
