export default theme => ({
    root: {
        maxWidth: '100%',
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3
    },
    imageWrapper: {
        height: '64px',
        width: '64px',
        margin: '0 auto',
        border: '1px solid #EDF0F2',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    details: {},
    title: {
        fontSize: '18px',
        lineHeight: '21px',
        textAlign: 'center',
        marginTop: theme.spacing.unit * 2
    },
    description: {
        lineHeight: '16px',
        height: theme.spacing.unit * 4,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.palette.text.secondary,
        textAlign: 'center',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2
    },
    actionText: {
        color: '#0767db',
        fontSize: '14px'
    },
    action: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '15px',
        color: 'green'
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing.unit
    },
    workText: {
        marginLeft: theme.spacing.unit,
        color: theme.palette.text.hint
    },
    soldIcon: {
        color: theme.palette.text.hint
    },
    unsoldIcon: {
        marginLeft: 'auto',
        color: theme.palette.text.hint
    },
    textDark: {
        // color: theme.palette.text.primary
        color: '#49a21c'

    },
    textSold: {
        // color: theme.palette.text.primary
        color: '#49a21c'

    },
    textUnSold: {
        // color: theme.palette.text.primary
        color: '#ff740e'

    }
});
