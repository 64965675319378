function getAuthenicationToken() {
  if(localStorage.getItem('authToken')){
    return true;
  }else{
    return false;
  }
}
function setAuthenicationToken(store) {
  if(store){
    localStorage.setItem('authToken',store.authToken);
    localStorage.setItem('refreshToken',store.refreshToken);
    localStorage.setItem('authId',store.authId);
    localStorage.setItem('authType',store.authType);
    localStorage.setItem('authEmail',store.authEmail);
    localStorage.setItem('authName',store.authName);
    localStorage.setItem('idleTimeLogout',new Date().getTime());
    return true;
  }
  return false;
  
}
function setHeader(){
  if(localStorage.getItem('authToken')){
    return {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('authToken')
    };
  }else{
    return {
      'Content-Type': 'application/json'
    };
  }
}

function authDetail(){
  return{
    authToken : localStorage.getItem('authToken'),
    refreshToken : localStorage.getItem('refreshToken'),
    authId : localStorage.getItem('authId'),
    authType:localStorage.getItem('authType'),
    authEmail:localStorage.getItem('authEmail')
  }
}

function setFormDataHeader(){
  return {
    'Content-Type': 'multipart/form-data',
    'Authorization': localStorage.getItem('authToken')
  };
  
}
function setIdleTimeLogout(){
  let time =localStorage.setItem('idleTimeLogout',new Date().getTime());
  return time; 
}
function getIdleTimeLogout(){
  let idealTime =localStorage.getItem('idleTimeLogout');
  let current = new Date().getTime();
  let delta = Math.abs((current -idealTime) / 1000);
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  return (delta > 10 ? true : false); 
}
export default {
  getAuthenicationToken,
  setAuthenicationToken,
  authDetail,
  setHeader,
  setFormDataHeader,
  setIdleTimeLogout,
  getIdleTimeLogout
};
